<template>
  <NotificationBase
    :notification
    :icon="{ name: 'freespins' }"
    :compact
    link="/personal/transactions?tab=deposit"
  >
    <template #subject>
      {{ t('notifications.subject.freespinsWinAccrual') }}
    </template>
    <i18n-t keypath="notifications.body.freespinsWinAccrual" tag="p">
      <template #amount>
        <b>{{ notificationAmount }}</b>
      </template>
    </i18n-t>
  </NotificationBase>
</template>

<script setup lang="ts">
import NotificationBase from '../NotificationBase.vue'
import type { AccountTransactionNotification } from '../../../../types'

const { notification, compact = false } = defineProps<{
  notification: AccountTransactionNotification
  compact?: boolean
}>()

const { notificationAmount } = useCurrencyNotification(
  toRef(() => notification),
)

const { t } = useI18n()
</script>
